import { post, get, postDocument, getDocument } from '../../api'

export async function branchesAsync (formData = {}, onLogOut) {
  // console.log(formData, onLogOut);
  return await post('general/select/branch', formData, onLogOut)
}

export async function statesAsync () {
  return await post('general/select/states')
}

export async function usersAsync () {
  return await post('general/select/users')
}

export async function productSearchAsync () {
  return await post('general/select/product-search')
}

export async function typeLossAsync () {
  return await post('general/select/type-loss')
}

export async function customerSuppliersAsync (formData) {
  return await post('general/select/customer-supplier', formData)
}

export async function customerSuppliersAdvanceAsync (formData) {
  return await post('general/select/customer-supplier-advance', formData)
}

export async function typeVehicleAsync () {
  return await post('general/select/type-vehicle')
}

export async function productsAsync () {
  return await post('general/select/products')
}

export async function unitMeasurementAsync () {
  return await post('general/select/unit-measurement')
}

export async function branchAsync (formData) {
  return await post('general/select/list-branch', formData)
}

export async function productByBranchAsync (formData) {
  return await post('general/select/product-by-branch', formData)
}

export async function pricesByProductAsync (formData) {
  return await post('general/select/prices-by-product', formData)
}

export async function typeAccountAsync () {
  return await post('general/select/type-account')
}

export async function typeTransactionAsync () {
  return await post('general/select/type-transaction')
}

export async function typeWeighingAsync () {
  return await post('general/select/type-weighing')
}

export async function listPricesAsync (formData) {
  return await post('general/select/list-prices', formData)
}

export async function listTimeZonesAsync (formData) {
  return await post('general/select/timezones', formData)
}

export async function listDateFormatsAsync (formData) {
  return await post('general/select/dateformats', formData)
}

export async function listCoinsAsync (formData) {
  return await post('general/select/coins', formData)
}

export async function listLanguagesAsync (formData) {
  return await post('general/select/languages', formData)
}

export async function listRepresentCoinsAsync (formData) {
  return await post('general/select/represent-coins', formData)
}

export async function listNumberDecimalsAsync (formData) {
  return await post('general/select/number-decimals', formData)
}

export async function listSeparatorDecimalsAsync (formData) {
  return await post('general/select/separator-decimals', formData)
}

export async function listCountriesAsync (formData) {
  return await post('general/select/countries', formData)
}

export async function getTaxRegimesAsyncs (formData) {
  return await post('general/select/tax-regimes', formData)
}

export async function getUseInvoicesAsyncs (formData) {
  return await post('general/select/use-invoices', formData)
}

export async function getTypeMovement (formData) {
  return await post('general/select/type-movement', formData)
}

export async function getTransactionTypesAsync () {
  return await post('general/select/transaction-types')
}

export async function incomeExpenseCategoriesAsync (formData) {
  return await post('general/select/income-expense-categories', formData)
}

export async function incomeExpenseCategoriesQueryAsync (query) {
  return await post('general/select/income-expense-categories', query.queryKey[1])
}

export async function accountsAsync (formData) {
  return await post('general/select/accounts', formData)
}

export async function accountsQueryAsync (query) {
  return await post('general/select/accounts')
}

export async function rolesAsync () {
  return await post('general/select/roles')
}

export async function conektaCard () {
  return await get('conekta/card')
}

export async function termsAsync () {
  return await post('general/select/terms-and-condition')
}

export async function privacyAsync () {
  return await post('general/select/privacy-policy')
}

export async function weighingStatus () {
  return await post('general/select/weighing-status')
}

export async function reportView (formData) {
  return await post('general/select/report-view', formData)
}

export async function excel (formData) {
  return await post('excel', formData)
}

export async function pdf (formData) {
  return await getDocument('pdf?module=weighing_general&report=dc7d2fdb-94d7-4ae8-bf1a-75b3b5f87e9b', 'archivo')
}

export async function advance (formData) {
  return await post('general/select/advance', formData)
}

export async function purchase (formData) {
  return await post('general/select/purchase', formData)
}

export async function sale (formData) {
  return await post('general/select/sale', formData)
}

export async function foil (formData) {
  return await post('general/select/foil', formData)
}

export async function cut (formData) {
  return await post('general/select/cash_cut', formData)
}

export async function incomeExpenseCategories () {
  return await post('general/select/income-expense-categories')
}
